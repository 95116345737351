// B"H
import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#1C3854",
        height: "100vh",
        alignItems: "center",
        color: "#fff",
        fontFamily: "Poppins, sans-serif",
      }}
    >
      <div
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "10%",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Zuz Giving Technologies Pty Ltd</h1>
        <h2>BzH, Coming soon!</h2>
      </div>
    </div>
  );
}

export default App;
